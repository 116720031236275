// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/components/systemsBlock.scss'

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetBlocks} from "../redux/actions";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {BLOCKS_TYPE} from "../constants/constType";


function SystemsBlock(props){
    const {blocksList,staticTexts,selectedLanguage} = props

    useEffect(()=>{
        props.GetBlocks(BLOCKS_TYPE.SYSTEMS)
    },[selectedLanguage])



    return <div className={'systems-wrapper'}>
        <div className={'systemsBlocks-title'}>
            {staticTexts?.systems_blocks_title}
        </div>
        <div className={'systems-blocks'}>
            {
                blocksList?.map(block => {
                    return <div className={'systems'} key={block?.id}>
                        <div className={'system-icon'}>
                            <img src={generateImageMediaUrl(block?.icon?.path)}/>
                        </div>
                        <div className={'system-title'}>
                            {block?.title}
                        </div>
                        <div className={'systems-description'} dangerouslySetInnerHTML={{__html: block?.description}}/>
                    </div>
                })
            }
        </div>
    </div>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'blocksList',
        'staticTexts',
        'selectedLanguage'
    ])
};
const mapDispatchToProps = {
    GetBlocks,
};

export default connect(mapStateToProps,mapDispatchToProps)(SystemsBlock);